import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import {
  spacer,
  colors,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text, {
  ErrorText,
  SuccessText,
} from '@dqp/common/components/Text/Text'
import Title from '@dqp/common/components/Title/Title'
import Image from '@dqp/common/components/Image'
import { get } from 'lodash-es'
import { navigate } from '@reach/router'
import { useUserContext } from '#context/UserContext'
import {
  useResendVerificationEmail,
  useVerifyEmail,
  useLogout,
} from '#api/auth'
import routes from '#globals/routes'
import useParseQueryParams from '#utils/useParseQueryParams'
import Stack from '#components/Stack/Stack'
import getErrorMessage from '#utils/getErrorMessage'

const styles = {
  contaner: css`
    background-color: ${colors.purple};
    ${breakpoints({
      paddingTop: [40, 40, 160, 190, 190],
      paddingBottom: [80, 80, 145, 210, 210],
    })};
  `,
  title: css`
    ${spacer.mrB20}
  `,
  text: css`
    ${spacer.mrB30}
  `,
  crown: css`
    ${breakpoints({
      top: [null, null, null, 50, 50],
      right: [null, null, null, -240, -230],
    })};
  `,
  icecream: css`
    ${breakpoints({
      left: [null, null, null, -240, -180],
      top: [null, null, null, -172, -172],
    })};
  `,
  pencil: css`
    ${breakpoints({
      top: [null, null, null, -120, -120],
      right: [null, null, null, -240, -200],
    })};
  `,
}
function Hero() {
  const { user } = useUserContext()
  const { logout } = useLogout()
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const { token, email: emailFromQuery } = useParseQueryParams()
  const {
    verifyEmail,
    loading: verifyEmailLoading,
  } = useVerifyEmail()

  const {
    resendVerificationEmail,
    loading,
  } = useResendVerificationEmail()
  const email = emailFromQuery || get(user, 'user.email')
  const handleClick = () =>
    resendVerificationEmail({
      variables: { email },
    })
      .then(({ data }) => {
        const { message } = get(data, 'resendVerificationEmail', {})
        if (message) setSuccessMessage(message)
      })
      .catch(error => {
        const message = getErrorMessage(error)
        if (message) setErrorMessage(message)
      })

  useEffect(() => {
    if (token) {
      verifyEmail({
        variables: { token },
      }).catch(error => {
        const message = getErrorMessage(error)
        if (message) setErrorMessage(message)
      })
    }
  }, [token, verifyEmail])

  if (!token && !user) {
    navigate(routes.login.to)
    return null
  }

  return (
    <section css={styles.contaner}>
      <div className='relative container text-center'>
        <div className='row justify-content-center'>
          <Stack
            spacing={20}
            className='col-lg-7 text-center d-flex flex-column align-items-center'
          >
            <Title
              as='h1'
              size='xxxxLarge'
              weight='bold'
              color={colors.white}
            >
              Verify{verifyEmailLoading && 'ing'} your email
            </Title>
            {!verifyEmailLoading && (
              <Text color={colors.white} size='large'>
                We’ve sent an email to <strong>{email}</strong>.
                Please check your inbox and click the link to
                continue.
              </Text>
            )}
            {errorMessage && (
              <ErrorText size='large'>{errorMessage}</ErrorText>
            )}
            {successMessage ? (
              <SuccessText color={colors.white} size='large'>
                {successMessage}
              </SuccessText>
            ) : (
              <Button
                variant='black'
                size='medium'
                isLoading={loading || verifyEmailLoading}
                onClick={handleClick}
              >
                RESEND EMAIL
              </Button>
            )}
            {user && (
              <Button
                onClick={logout}
                underlineColor={colors.black}
                style={{ marginTop: -10 }}
              >
                Logout
              </Button>
            )}
          </Stack>
        </div>
        <Image
          css={styles.icecream}
          className='d-none d-lg-block'
          alt='icecream'
          image='verifyemail/icecream'
          sizes={{
            lg: {
              width: 391,
            },
            md: {},
          }}
        />
        <Image
          css={styles.pencil}
          className='d-none d-lg-block'
          alt='pencil'
          image='verifyemail/pencil'
          sizes={{
            lg: {
              width: 292,
            },
            md: {},
          }}
        />
        <Image
          css={styles.crown}
          className='d-none d-lg-block'
          alt='crown'
          image='verifyemail/crown'
          sizes={{
            lg: {
              width: 640,
            },
            md: {},
          }}
        />
      </div>
    </section>
  )
}

Hero.propTypes = {}
export default Hero
